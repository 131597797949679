<template>
  <!-- 关于七腾,英文版 -->
  <div v-if="elmentList.length">
    <div class="about" v-if="$store.state.pageset.pcshow">
      <el-tabs v-model="activeName">
        <el-tab-pane label="Company Profile" name="one">
          <div class="about-introduce">
            <div class="img-con">
              <img :src="elmentList[0].pcImg" />
              <div class="title">
                <h1 v-animate="'queue-bottom'">
                  {{ elmentList[0].descList[0].descName }}
                </h1>
                <h1 v-animate="'queue-bottom'">
                  {{ elmentList[0].descList[1].descName }}
                </h1>
              </div>
            </div>
            <div class="dec">
              <div class="dec-par">
                <p
                  class="p1"
                  v-animate="'queue-bottom'"
                  v-html="changeEMSP(elmentList[0].descList[2].descName)"
                ></p>
                <p
                  class="p2"
                  v-animate="'queue-bottom'"
                  v-html="changeEMSP(elmentList[0].descList[3].descName)"
                ></p>
                <p
                  class="p2"
                  v-animate="'queue-bottom'"
                  v-html="changeEMSP(elmentList[0].descList[4].descName)"
                ></p>
                <p
                  class="p2"
                  v-animate="'queue-bottom'"
                  v-html="changeEMSP(elmentList[0].descList[5].descName)"
                ></p>
                <p
                  class="p2"
                  v-animate="'queue-bottom'"
                  v-html="changeEMSP(elmentList[0].descList[6].descName)"
                ></p>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Company History" name="two">
          <div class="about-course">
            <div class="img-con">
              <img :src="elmentList[1].pcImg" />
            </div>
            <p class="p-title">{{ elmentList[1].descList[0].descName }}</p>
            <p
              class="p-dec"
              v-html="changeEMSP(elmentList[1].descList[1].descName)"
            ></p>
            <div class="course-con">
              <div
                v-for="index in 5"
                :key="index"
                :class="'course-item' + ' item' + index"
              >
                <div :class="'img-con' + ' img' + index">
                  <img :src="elmentList[index + 1].pcImg" />
                  <div class="img-dec">
                    <p class="img-title">
                      {{ elmentList[index + 1].descList[0].descName }}
                    </p>
                    <p
                      class="img-dec"
                      v-html="
                        changeEMSP(elmentList[index + 1].descList[1].descName)
                      "
                    ></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="course-con">
              <div
                v-for="index in 5"
                :key="index"
                class="course-time course-interval"
              >
                <p class="time-title">
                  {{ elmentList[index + 1].descList[2].descName }}
                </p>
              </div>
            </div>
            <div class="course-con">
              <div
                v-for="index in 5"
                :key="index"
                class="course-time course-ruler"
                :class="'course-ruler' + index"
              >
                <div class="time-ruler">
                  <div
                    :class="'time-ruler-son' + ' ruler' + indexTime"
                    v-for="indexTime in 7"
                    :key="indexTime"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Awards" name="three">
          <div class="certificate about-honor">
            <p class="p-title">{{ elmentList[7].descList[0].descName }}</p>
            <p
              class="p-dec"
              v-html="changeEMSP(elmentList[7].descList[1].descName)"
            ></p>
            <div class="honor-con">
              <div class="con-item" v-for="index in 18" :key="index">
                <div class="con-item-bc" v-if="elmentList[index + 7].pcImg">
                  <div>
                    <div class="con-item-bc-img">
                      <div class="con-item-bc-img-border">
                        <img :src="elmentList[index + 7].pcImg" />
                      </div>
                    </div>
                    <p class="honor-item-name">
                      {{ elmentList[index + 7].descList[0].descName }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 回到顶部按钮 -->
          <div class="top" @click="scollTop">
            <i class="el-icon-caret-top"></i>
            <p>顶部</p>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Patents" name="four">
          <div class="certificate about-patent">
            <p class="p-title">{{ elmentList[26].descList[0].descName }}</p>
            <p
              class="p-dec"
              v-html="changeEMSP(elmentList[26].descList[1].descName)"
            ></p>
            <div class="honor-con">
              <div class="con-item" v-for="index in 18" :key="index">
                <div class="con-item-bc" v-if="elmentList[index + 26].pcImg">
                  <div>
                    <div class="con-item-bc-img">
                      <div class="con-item-bc-img-border">
                        <img :src="elmentList[index + 26].pcImg" />
                      </div>
                    </div>
                    <p class="honor-item-name">
                      {{ elmentList[index + 26].descList[0].descName }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 回到顶部按钮 -->
          <div class="top" @click="scollTop">
            <i class="el-icon-caret-top"></i>
            <p>顶部</p>
          </div></el-tab-pane
        >
        <el-tab-pane label="Industry Status" name="five">
          <div class="about-industry">
            <img :src="elmentList[45].pcImg" />
            <div class="industry-con">
              <p
                class="p1"
                v-html="changeEMSP(elmentList[45].descList[0].descName)"
              ></p>
              <p
                class="p2"
                v-html="changeEMSP(elmentList[45].descList[1].descName)"
              ></p>
              <p
                class="p2"
                v-html="changeEMSP(elmentList[45].descList[2].descName)"
              ></p>
              <p
                class="p2"
                v-html="changeEMSP(elmentList[45].descList[3].descName)"
              ></p>
              <p
                class="p2"
                v-html="changeEMSP(elmentList[45].descList[4].descName)"
              ></p>
              <p
                class="p2"
                v-html="changeEMSP(elmentList[45].descList[5].descName)"
              ></p>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 手机端 -->
    <div class="about" v-else>
      <el-collapse v-model="activeNames">
        <el-collapse-item title="Company Info" name="1">
          <ul>
            <li @click="openAbout('introduce')">Company Profile</li>
            <li @click="openAbout('course')">Development History</li>
            <li @click="openAbout('honor')">Honor</li>
            <li @click="openAbout('patent')">Patent</li>
            <li @click="openAbout('industry')">Industry Position</li>
          </ul>
        </el-collapse-item>
      </el-collapse>
      <!-- 公司简介 -->
      <div class="about-introduce" v-if="mobileOpenType === 'introduce'">
        <div class="img-con">
          <img :src="elmentList[0].phoneImg" />
          <div class="title">
            <h1 v-animate="'queue-bottom'">
              {{ elmentList[0].descList[0].descName }}
            </h1>
            <h1 v-animate="'queue-bottom'">
              {{ elmentList[0].descList[1].descName }}
            </h1>
          </div>
        </div>
        <div class="dec">
          <div class="dec-par">
            <p
              class="p1"
              v-animate="'queue-bottom'"
              v-html="changeEMSP(elmentList[0].descList[2].descName)"
            ></p>
            <p
              class="p2"
              v-animate="'queue-bottom'"
              v-html="changeEMSP(elmentList[0].descList[3].descName)"
            ></p>
            <p
              class="p2"
              v-animate="'queue-bottom'"
              v-html="changeEMSP(elmentList[0].descList[4].descName)"
            ></p>
            <p
              class="p2"
              v-animate="'queue-bottom'"
              v-html="changeEMSP(elmentList[0].descList[5].descName)"
            ></p>
            <p
              class="p2"
              v-animate="'queue-bottom'"
              v-html="changeEMSP(elmentList[0].descList[6].descName)"
            ></p>
          </div>
        </div>
      </div>
      <!-- 发展历程 -->
      <div class="about-course" v-else-if="mobileOpenType === 'course'">
        <div class="img-con">
          <img :src="elmentList[1].phoneImg" />
        </div>
        <p class="p-title">{{ elmentList[1].descList[0].descName }}</p>
        <p
          class="p-dec"
          v-html="changeEMSP(elmentList[1].descList[1].descName)"
        ></p>
        <div class="course-con">
          <div v-for="index in 5" :key="index">
            <div class="img-con">
              <p class="img-title">
                {{ elmentList[7 - index].descList[2].descName }}
              </p>
              <div class="img-line"></div>
              <img :src="elmentList[7 - index].phoneImg" />
              <div class="img-dec">
                <p class="img-title">
                  {{ elmentList[7 - index].descList[0].descName }}
                </p>
                <p class="img-dec">
                  {{ elmentList[7 - index].descList[1].descName }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 荣誉资质 -->
      <div
        class="certificate about-honor"
        v-else-if="mobileOpenType === 'honor'"
      >
        <p class="p-title">{{ elmentList[7].descList[0].descName }}</p>
        <p
          class="p-dec"
          v-html="changeEMSP(elmentList[7].descList[1].descName)"
        ></p>
        <div class="honor-con">
          <div class="con-item" v-for="index in 18" :key="index">
            <div class="con-item-bc" v-if="elmentList[index + 7].phoneImg">
              <div>
                <div class="con-item-bc-img">
                  <div class="con-item-bc-img-border">
                    <img :src="elmentList[index + 7].phoneImg" />
                  </div>
                </div>
                <p class="honor-item-name">
                  {{ elmentList[index + 7].descList[0].descName }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 专利技术 -->
      <div
        class="certificate about-patent"
        v-else-if="mobileOpenType === 'patent'"
      >
        <p class="p-title">{{ elmentList[26].descList[0].descName }}</p>
        <p
          class="p-dec"
          v-html="changeEMSP(elmentList[26].descList[1].descName)"
        ></p>
        <div class="honor-con">
          <div class="con-item" v-for="index in 18" :key="index">
            <div class="con-item-bc" v-if="elmentList[index + 26].phoneImg">
              <div>
                <div class="con-item-bc-img">
                  <div class="con-item-bc-img-border">
                    <img :src="elmentList[index + 26].phoneImg" />
                  </div>
                </div>
                <p class="honor-item-name">
                  {{ elmentList[index + 26].descList[0].descName }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 行业地位 -->
      <div class="about-industry" v-else-if="mobileOpenType === 'industry'">
        <img :src="elmentList[45].phoneImg" />
        <div class="industry-con">
          <p
            class="p1"
            v-html="changeEMSP(elmentList[45].descList[0].descName)"
          ></p>
          <p
            class="p2"
            v-html="changeEMSP(elmentList[45].descList[1].descName)"
          ></p>
          <p
            class="p2"
            v-html="changeEMSP(elmentList[45].descList[2].descName)"
          ></p>
          <p
            class="p2"
            v-html="changeEMSP(elmentList[45].descList[3].descName)"
          ></p>
          <p
            class="p2"
            v-html="changeEMSP(elmentList[45].descList[4].descName)"
          ></p>
          <p
            class="p2"
            v-html="changeEMSP(elmentList[45].descList[5].descName)"
          ></p>
        </div>
      </div>
    </div>
  </div>
  <div v-else v-loading="true"></div>
</template>
<script>
import about_en from "./about_en.js";

export default about_en;
</script>
<style scoped lang="scss">
@import "~@/assets/style/common.scss";
@import "about_en.scss";
</style>
