
export default {
    components: {

    },
    data() {
        return {
            activeName: 'one',
            // elmentList: [
            //     {
            //         pcElementId: 350,
            //         phoneElementId: 375,
            //         descList: [
            //             {
            //                 descName: 'Sevnce Robotics Copmany',
            //                 sort: 1
            //             }, {
            //                 descName: 'Ltd.is the leading enterprise of the global robotics industry.',
            //                 sort: 2
            //             }
            //             , {
            //                 descName: 'Company introduction ',
            //                 sort: 3
            //             }, {
            //                 descName: '七机器人有限公司(以下简称"七机器人")成立于2010年，是一家集特种器人设计、研发生产、售、服务为一体的局新技术企业，在应急安全领域处于头部她位七腾机器人旨在通过自研特种机器人服务于全球应急安全终端市场，将科技创新融入产品和服务，形成多业态融合、多场景覆盖的格局，为社会安全保驾护航。腾机器人重承“让社会重智能”的企业愿蛋，以“成为全球机器人领先企业”为使命，聚焦石油、化工、电气等行业应用，拥有8项核心技术、16项势功能、468种算法药量，形成以硬件为基础、软件为引警、算法为核心的完整AI技术链。',
            //                 sort: 4
            //             }, {
            //                 descName: '针对各类高温高压、有毒有害、易燃易爆、危险物生产、仓储等环境，产品涵盖防爆化工轮式巡检机器人防爆化工四足机器人，防爆化工挂轨巡检机器人等为中石油、中石化、中海油、中化集团、巴斯夫等海内外知名企业提供产品服务和解决方案，已开拓欧洲、非洲、东南亚、西北亚等海外市场。截至目前，七腾机器人已为全球40多个国家及地区的上千家企业有效解决了六千多个痛点难点问题。',
            //                 sort: 5
            //             }
            //             , {
            //                 descName: '七腾机器人总部及全球研发中心位于中国第三个国家级新区-重庆两江新区设立北京全球战略中心和深圳供应链中心，分别在重庆江西设立机器人生产基地，并建立全球营销和售后服务网点。',
            //                 sort: 6
            //             }, {
            //                 descName: '七机器人注重自主创新，引进海内外行业技术领军人才组建百余人研发队，建有四足仿生机器人实验室、智能巡检机器人实验室、抗射机器人实验室。企业获得百余项专利技术成果，荣获国家知识产权优势企业、专精特新“小巨人”企业、重庆市新型研发机构、智能巡检机器人综合实力竞争企业等国家级、省部级、行业级企业荣营。未来，七腾机器人将聚焦客户需求，以技术创新为引警，拓展行业应用场景，促进服务满意度再升级，持续布局国际市场，致力发展成为具有全球竞争力的机器人头部企业。',
            //                 sort: 7
            //             }],
            //         type: 'image',
            //         sort: 1
            //     },
            //     // 发展历程
            //     {
            //         pcElementId: 351,
            //         phoneElementId: 376,
            //         descList: [
            //             {
            //                 descName: 'Profile  Development',
            //                 sort: 1
            //             }, {
            //                 descName: 'Sevnce robotics short range design: Sevnce will give play to its core technical advantages, optimize product series and improve product lines, promote the diversification of robot industry chain, improve performance and quality, deepen industry applications, move towards more humanized intelligent high-end robots, and continue to promote the strategic layout of globalization.',
            //                 sort: 2
            //             }],
            //         type: 'image',
            //         sort: 2
            //     },
            //     {
            //         pcElementId: 369,
            //         phoneElementId: 377,
            //         descList: [
            //             {
            //                 descName: 'Company establishment',
            //                 sort: 1
            //             }, {
            //                 descName: 'CTS1.0 software came out and was widely used. In 2010, Chongqing Sevnce Technology (now renamed Sevnce Robotics) was established, which explored a development path with software customization development as its main business and stepped into the threshold of software customization service field.',
            //                 sort: 2
            //             }, {
            //                 descName: 'Year 2008~2010',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort: 3
            //     },
            //     {
            //         pcElementId: 370,
            //         phoneElementId: 378,
            //         descList: [
            //             {
            //                 descName: 'Innovation and upgrading ',
            //                 sort: 1
            //             }, {
            //                 descName: 'Sevnce Robotics in the twists and turns forward, realize that mastering the core technical advantages is the only way for the company to mature, Sevnce Robotics began the first transformation and upgrading, to provide customers with industry information and intelligent system overall solutions',
            //                 sort: 2
            //             }, {
            //                 descName: 'Year 2015~2017',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort: 4
            //     },
            //     {
            //         pcElementId: 371,
            //         phoneElementId: 379,
            //         descList: [
            //             {
            //                 descName: 'Enterprise transformation ',
            //                 sort: 1
            //             }, {
            //                 descName: 'Reshape strategic positioning, shift to the direction of intelligent equipment products, and begin to explore the intelligent robot market in 2017, officially opening a new chapter in the field of special robot service emergency safety',
            //                 sort: 2
            //             }, {
            //                 descName: 'Year 2011～2014',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort: 5
            //     },
            //     {
            //         pcElementId: 372,
            //         phoneElementId: 380,
            //         descList: [
            //             {
            //                 descName: 'Sustainable development ',
            //                 sort: 1
            //             }, {
            //                 descName: 'Sevnce Robotics innovation capacity continues to upgrade, Chongqing and Jiangxi two production bases officially put into operation, fire, power, petrochemical intelligent robots have come out and achieve mass production, the same period of explosion-proof chemical wheeled inspection robot comprehensive strength industry leading, so as to achieve special robot design, research and development, production, sales, service integration',
            //                 sort: 2
            //             }, {
            //                 descName: 'Year 2018～2020',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort: 6
            //     },
            //     {
            //         pcElementId: 373,
            //         phoneElementId: 373,
            //         descList: [
            //             {
            //                 descName: 'Forefront of the industry',
            //                 sort: 1
            //             }, {
            //                 descName: 'Sevnce Robotics achieved "quality" leap: design and development capabilities, innovative technological achievements, enterprise comprehensive strength among the industry head position, global marketing and after-sales service outlets have been fully completed, Sevnce Robotics products to lightweight, biochemical imitation, quadruped transformation, application scenarios to forests, uninhabited areas, mining areas and other complex, non-paved road conditions, etc. Application industries to petrochemical, security, power, coal, steel, environmental protection and other fields.',
            //                 sort: 2
            //             }, {
            //                 descName: 'Year 2021～Now',
            //                 sort: 3
            //             }],
            //         type: 'image',
            //         sort: 7
            //     },
            //     // 荣誉资质
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: 'Honor and qualification',
            //                 sort: 1
            //             }, {
            //                 descName: 'Up to now, Sevnce Robotics has won 5 national honorary awards, 11 provincial and ministerial honorary awards, industry honorary awards, and more than 35 qualification certifications',
            //                 sort: 2
            //             }
            //         ],
            //         type: 'image',
            //         sort: 8
            //     },
            //     {
            //         pcElementId: 352,
            //         phoneElementId: 352,
            //         descList: [
            //             {
            //                 descName: 'High-Tech Enterprise',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 9
            //     },
            //     {
            //         pcElementId: 353,
            //         phoneElementId: 353,
            //         descList: [
            //             {
            //                 descName: 'National intellectual property advantage enterprises',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 10
            //     },
            //     {
            //         pcElementId: 354,
            //         phoneElementId: 354,
            //         descList: [
            //             {
            //                 descName: 'Chongqing enterprise technology center',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 11
            //     },
            //     {
            //         pcElementId: 355,
            //         phoneElementId: 355,
            //         descList: [
            //             {
            //                 descName: 'Credit AAA',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 12
            //     },
            //     {
            //         pcElementId: 356,
            //         phoneElementId: 356,
            //         descList: [
            //             {
            //                 descName: 'Outstanding Case',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 13
            //     },
            //     {
            //         pcElementId: 357,
            //         phoneElementId: 357,
            //         descList: [
            //             {
            //                 descName: 'Chongqing first set (set) major technical equipment',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 14
            //     },
            //     {
            //         pcElementId: 358,
            //         phoneElementId: 358,
            //         descList: [
            //             {
            //                 descName: 'Specialized& new technology little giant ',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 15
            //     },
            //     {
            //         pcElementId: 359,
            //         phoneElementId: 359,
            //         descList: [
            //             {
            //                 descName: 'Comprehensive competitive enterprise',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 16
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 17
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 18
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 19
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 20
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 21
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 22
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 23
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 24
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 25
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 26
            //     },

            //     // 专利技术
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: 'Patented technology ',
            //                 sort: 1
            //             },
            //             {
            //                 descName: 'It has 8 core technologies, 16 leading functions, 468 algorithms, and has obtained more than 100 patented technical achievements',
            //                 sort: 2
            //             }
            //         ],
            //         type: 'image',
            //         sort: 27
            //     },
            //     {
            //         pcElementId: 360,
            //         phoneElementId: 360,
            //         descList: [
            //             {
            //                 descName: '伺服泵控直驱机器人腿足系统',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 28
            //     },
            //     {
            //         pcElementId: 361,
            //         phoneElementId: 361,
            //         descList: [
            //             {
            //                 descName: '大数据分析系统v1.0',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 29
            //     },
            //     {
            //         pcElementId: 362,
            //         phoneElementId: 362,
            //         descList: [
            //             {
            //                 descName: '防爆通用底盘及轮式巡检机器人',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 30
            //     },
            //     {
            //         pcElementId: 363,
            //         phoneElementId: 363,
            //         descList: [
            //             {
            //                 descName: '外观设计专利',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 31
            //     },
            //     {
            //         pcElementId: 364,
            //         phoneElementId: 364,
            //         descList: [
            //             {
            //                 descName: '巡检管理系统',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 32
            //     },
            //     {
            //         pcElementId: 365,
            //         phoneElementId: 365,
            //         descList: [
            //             {
            //                 descName: '降噪式底盘及巡检机器人',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 33
            //     },
            //     {
            //         pcElementId: 366,
            //         phoneElementId: 366,
            //         descList: [
            //             {
            //                 descName: 'Legged robot',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 34
            //     },
            //     {
            //         pcElementId: 367,
            //         phoneElementId: 367,
            //         descList: [
            //             {
            //                 descName: 'Hydraulic pump controlled explosion-proof four-legged robot ',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 35
            //     },
            //     {
            //         pcElementId: 368,
            //         phoneElementId: 368,
            //         descList: [
            //             {
            //                 descName: 'PTZ scanning laser combustible gas detection system V1.0',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 36
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 37
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 38
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 39
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 40
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 41
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 42
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 43
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 44
            //     },
            //     {
            //         pcElementId: '',
            //         phoneElementId: '',
            //         descList: [
            //             {
            //                 descName: '',
            //                 sort: 1
            //             }
            //         ],
            //         type: 'image',
            //         sort: 45
            //     },
            //     {
            //         pcElementId: 374,
            //         phoneElementId: 381,
            //         descList: [
            //             {
            //                 descName: 'Industry status',
            //                 sort: 1
            //             },
            //             {
            //                 descName: 'In recent years, Sevnce Robotics adhere to the "innovation-driven, application traction, foundation improvement, integrated development", innovation vitality burst out, scientific and technological achievements continue to emerge, market competitiveness steadily improved, product market share in the forefront of the country, become the head enterprise in the field of emergency safety. ',
            //                 sort: 2
            //             },
            //             {
            //                 descName: 'In 2021, the explosion-proof chemical intelligent inspection robot produced by Sevnce Robotics is in a leading position in the industry. ',
            //                 sort: 3
            //             },
            //             {
            //                 descName: 'In April 2022, Sevnce Robotics won the "2021 Intelligent inspection Robot Comprehensive Competitiveness Enterprise Award" issued by OFweek.',
            //                 sort: 4
            //             },
            //             {
            //                 descName: 'In June 2022, OFweek released the "2022 China Intelligent Inspection Robot Industry Development Research White Paper", and Sevnce Robotics ranked first on the list of "intelligent inspection robot industry head manufacturers". ',
            //                 sort: 5
            //             },
            //             {
            //                 descName: 'In April 2023, Sevnce Robotics won the Annual Excellent Application Case Award of China Robot Industry 2022 by OFweek.',
            //                 sort: 6
            //             },
            //         ],
            //         type: 'image',
            //         sort: 46
            //     },
            // ],
            elmentList:[],
            mobileOpenType: 'introduce',
            activeNames: []
        }
    },
    created() {

        // this.$store.dispatch("pageset/getSourceData",
        //     {
        //         createBy: '',
        //         updateBy: '',
        //         elmentList: this.elmentList,
        //         pageId: this.$route.query.pageId,//页面id
        //         pageName: this.$route.path,
        //         path: this.$route.path,
        //         remark: '',
        //     }
        // );
        // 改变导航栏为黑色
        document.documentElement.style.setProperty('--var-primary-color', '#000000');
        this.$store.dispatch("pageset/getbannerIndex", 1);
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then((res) => {
            this.elmentList = res.data.elmentList
            setTimeout(() => {
            }, 100);
        });
    },
    methods: {
        // 回到顶部
        scollTop() {
            document.documentElement.scrollTop = 0;
        },
        openAbout(type) {
            this.mobileOpenType = type;
            this.activeNames = [];
        },
        changeEMSP(val) {
            return val.replace(/ /g, '&emsp;');
        }
    }
}